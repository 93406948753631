exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-500-index-jsx": () => import("./../../../src/pages/500/index.jsx" /* webpackChunkName: "component---src-pages-500-index-jsx" */),
  "component---src-pages-cookie-policy-index-jsx": () => import("./../../../src/pages/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-jsx" */),
  "component---src-pages-dosing-administration-index-jsx": () => import("./../../../src/pages/dosing-administration/index.jsx" /* webpackChunkName: "component---src-pages-dosing-administration-index-jsx" */),
  "component---src-pages-efficacy-long-term-outcomes-index-jsx": () => import("./../../../src/pages/efficacy/long-term-outcomes/index.jsx" /* webpackChunkName: "component---src-pages-efficacy-long-term-outcomes-index-jsx" */),
  "component---src-pages-efficacy-muscle-function-index-jsx": () => import("./../../../src/pages/efficacy/muscle-function/index.jsx" /* webpackChunkName: "component---src-pages-efficacy-muscle-function-index-jsx" */),
  "component---src-pages-efficacy-real-world-data-index-jsx": () => import("./../../../src/pages/efficacy/real-world-data/index.jsx" /* webpackChunkName: "component---src-pages-efficacy-real-world-data-index-jsx" */),
  "component---src-pages-getting-started-prescription-start-form-index-jsx": () => import("./../../../src/pages/getting-started/prescription-start-form/index.jsx" /* webpackChunkName: "component---src-pages-getting-started-prescription-start-form-index-jsx" */),
  "component---src-pages-getting-started-switch-considerations-index-jsx": () => import("./../../../src/pages/getting-started/switch-considerations/index.jsx" /* webpackChunkName: "component---src-pages-getting-started-switch-considerations-index-jsx" */),
  "component---src-pages-how-accumulating-real-world-evidence-enhancing-our-understanding-management-index-jsx": () => import("./../../../src/pages/how-accumulating-real-world-evidence-enhancing-our-understanding-management/index.jsx" /* webpackChunkName: "component---src-pages-how-accumulating-real-world-evidence-enhancing-our-understanding-management-index-jsx" */),
  "component---src-pages-important-safety-information-index-jsx": () => import("./../../../src/pages/important-safety-information/index.jsx" /* webpackChunkName: "component---src-pages-important-safety-information-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-references-index-jsx": () => import("./../../../src/pages/references/index.jsx" /* webpackChunkName: "component---src-pages-references-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-sign-up-confirmation-index-jsx": () => import("./../../../src/pages/sign-up/confirmation/index.jsx" /* webpackChunkName: "component---src-pages-sign-up-confirmation-index-jsx" */),
  "component---src-pages-sign-up-error-index-jsx": () => import("./../../../src/pages/sign-up-error/index.jsx" /* webpackChunkName: "component---src-pages-sign-up-error-index-jsx" */),
  "component---src-pages-sign-up-index-jsx": () => import("./../../../src/pages/sign-up/index.jsx" /* webpackChunkName: "component---src-pages-sign-up-index-jsx" */),
  "component---src-pages-sitemap-index-jsx": () => import("./../../../src/pages/sitemap/index.jsx" /* webpackChunkName: "component---src-pages-sitemap-index-jsx" */),
  "component---src-pages-study-summaries-index-jsx": () => import("./../../../src/pages/study-summaries/index.jsx" /* webpackChunkName: "component---src-pages-study-summaries-index-jsx" */),
  "component---src-pages-tolerability-safety-profile-index-jsx": () => import("./../../../src/pages/tolerability/safety-profile/index.jsx" /* webpackChunkName: "component---src-pages-tolerability-safety-profile-index-jsx" */),
  "component---src-pages-tolerability-weight-behavior-index-jsx": () => import("./../../../src/pages/tolerability/weight-behavior/index.jsx" /* webpackChunkName: "component---src-pages-tolerability-weight-behavior-index-jsx" */),
  "component---src-pages-unmet-need-index-jsx": () => import("./../../../src/pages/unmet-need/index.jsx" /* webpackChunkName: "component---src-pages-unmet-need-index-jsx" */)
}

