import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/* Set Global States here. */
const defaultState = {
	isExpanded: false,
    setExpanded: () => {},
	isBannerVisible: true,
	setIsBannerVisible: () => {},
};

const AppContext = React.createContext(defaultState);

const AppProvider = ({ children }) => {
	const [isExpanded, setExpanded] = useState(defaultState.isExpanded)
	const [isBannerVisible, setIsBannerVisible] = useState(defaultState.isBannerVisible);

	const store = {
        isExpanded: isExpanded,
        setExpanded: setExpanded,
		isBannerVisible: isBannerVisible,
		setIsBannerVisible: setIsBannerVisible,
	};

	useEffect(() => {
		if (window.location.href.indexOf('ed=1') > -1) {
			runScreenshots();
		}
	}, []);

	// actions here, are only applied when the page is completely loaded/rendered
	const runScreenshots = () => {
		const url = window.location.href;
		document.querySelector("html").classList.add('ed');

		if (url.includes('ssIsiNonFixed')) {
			const isi = document.querySelector('.sticky-tray');
			if (isi) {
				isi.style.display = 'none';
			}
		}

		if (url.includes('ssAnimationOff')) {
			// code to turn off animation
			const myanimations = document.querySelectorAll('img');

			for (const image of myanimations) {
				image.classList.remove('gatsby-image-wrapper');
			}
		}

		if ( url.includes("resourceBanner") ) {
			setIsBannerVisible(true)
		}
	};

	return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
	children: PropTypes.node,
};

export default AppContext;

export { AppProvider };
